:root {
  --black-dark: #000000;
  --dark: #424242;
  --black-light: #383838;
  --gray-light-menu: #fafafa;
  --gray-soft: #eeeff1;
  --gray-light: #f6f6f6;
  --gray-light-border: #d7d7e0;
  --gray-light-text: #a5a5a5;
  --gray-text: #dbdbdb;
  --gray-secondary: #b3b3b3;
  --gray-dark-border: #363636;
  --gray-dark: #979797;
  --gray-dark-text: #949494;
  --gray-border: #ebebeb;
  --gray-description: #727272;
  --gray-title: #aaaaaa;
  --white: #ffffff;
  --success: #00a455;
  --error: #db0000;

  --content-width: 1280px;
  --content-width-big: 1520px;
  --transition: all 0.2s ease-out;
}
